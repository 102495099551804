<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Businesses</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="business_profiles"
        :fields="fields"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        striped
        responsive
        @row-clicked="showDetails"
      >

        <template #cell(cover)="data">
          <b-avatar :src="getImage(data.value)" />
        </template>
        <template #cell(state_id)="data">
          {{ states.filter(item => item.id === data.value)[0].name }}
        </template>
        <template #cell(category_id)="data">
          {{ categories.filter(item => item.id === data.value)[0].name }}
        </template>
        <template #cell(status)="data">
          <b-form-checkbox
            :checked="data.value === true ? 'true' : 'false'"
            class="custom-control-success"
            name="check-button"
            switch
            @change="toggleBusinessProfile($event, data.item.id)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        class="my-0"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Uptate' : 'Add New'"
      hide-footer
      size="huge"
      @close="hide"
    >
      <b-card-text>
        <div class="bp_form_box">
          <div class="bp_form_side_bar">
            <div
              v-for="(data, index) in tab_items"
              :key="index"
              class="bp_tab_link"
              :class="is_tab_active === data.id ? 'bp_active' : ''"
              @click="showFormContent(data.id)"
            >
              <div class="bp_tab_counter ">
                {{ index + 1 }}
              </div>
              <div class="bp_text_view">
                <span> {{ data.title }} </span>
              </div>
            </div>
          </div>
          <div class="bp_form_view">
            <div class="form_content">
              <validation-observer ref="profileForm">
                <b-form>
                  <b-row v-if="is_tab_active === 1">
                    <b-col cols="6">
                      <b-form-group
                        label="Name"
                        label-for="h-name"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name"
                            v-model="profile.name"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Categories "
                        label-for="h-categories"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-categories"
                          v-model="profile.category_id"
                          :options="categories_options"
                          @change="changeCategory"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Email"
                        label-for="h-email"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            id="h-email"
                            v-model="profile.email"
                            type="email"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">

                      <b-form-group
                        label="Sub Categories "
                        label-for="h-sub-categories"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-sub-categories"
                          v-model="profile.sub_category_id"
                          :options="sub_categories_options"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Phone"
                        label-for="h-phone"
                        label-cols-md="12"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="phone"
                          rules="required"
                        >
                          <b-form-input
                            id="h-phone"
                            v-model="profile.phone"
                            type="text"
                            :state="errors.length > 0 ? false:null"
                            placeholder="phone"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Tags"
                        label-for="h-tags"
                        label-cols-md="12"
                      >
                        <MultipleSelect
                          :options="tag_options"
                          :select-options="selectedTags"
                          :remove="removeTags"
                          :selected="selectTags"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="License"
                        label-for="h-license"
                        label-cols-md="12"
                      >

                        <b-form-input
                          v-if="has_license"
                          id="h-license"
                          v-model="profile.license"
                          placeholder="license"
                        />
                        <b-form-checkbox
                          :checked="has_license ? true : false"
                          name="check-button"
                          switch
                          inline
                          :class="has_license ? 'mt-2' : ''"
                          @change="changeHasLicense"
                        >
                          has license
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Website"
                        label-for="h-website"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-website"
                          v-model="profile.website"
                          placeholder="website"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Title"
                        label-for="h-contact_person_title"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_title"
                          v-model="profile.contact_title"
                          placeholder="Contact person title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Name"
                        label-for="h-contact_name"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_name"
                          v-model="profile.contact_name"
                          placeholder="Contact person title"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Email"
                        label-for="h-contact_person_email"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_email"
                          v-model="profile.contact_email"
                          placeholder="Contact person email"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="6">
                      <b-form-group
                        label="Contact Person Phone"
                        label-for="h-contact_person_phone"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-contact_person_phone"
                          v-model="profile.contact_phone"
                          placeholder="Contact person phone"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      class="mt-2"
                    >
                      <hr>
                      <span>Members</span>
                      <b-form-group
                        label=""
                        label-for="h-member"
                        label-cols-md="12"
                      >
                        <b-form-select
                          id="h-member"
                          v-model="profile.member_id"
                          :options="user_options"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 2">
                    <!-- <b-col
                      cols="12"
                      class=""
                    >
                      <b-form-group
                        label="Find location"
                        label-for="h-latitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-latitude"
                          v-model="map_address"
                          placeholder="Enter Address to find location"
                        /></b-form-group>
                    </b-col>

                    <b-col
                      cols="6"
                      class=""
                    >
                      <b-form-group
                        label="Latitude"
                        label-for="h-latitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-latitude"
                          v-model="profile.lat"
                          placeholder="Enter Latitude"
                        /></b-form-group>
                    </b-col>

                    <b-col
                      cols="6"
                      class=""
                    >
                      <b-form-group
                        label="Logitude"
                        label-for="h-longitude"
                        label-cols-md="12"
                      >
                        <b-form-input
                          id="h-longitude"
                          v-model="profile.lng"
                          placeholder="Enter Longitude"
                        /></b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-success"
                        class="mt-2 mb-2"
                        block
                        @click="findCords"
                      >
                        Find Location
                      </b-button>
                    </b-col> -->

                    <b-col
                      cols="12"
                      class="mt-4"
                    >
                      <ServiceArea
                        :id="profile.id"
                        :update="is_update"
                      />
                    </b-col>
                    <!-- <b-col cols="12">
                      <div
                        v-if="is_tab_active === 2"
                        ref="mapContainer"
                        style="height: 380px; width: 100%"
                      />
                    </b-col> -->

                  </b-row>

                  <b-row v-if="is_tab_active === 3">
                    <b-col cols="12">
                      <ServiceOffer
                        :id="profile.id"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 4">
                    <b-col cols="12">
                      <quill-editor
                        v-model="profile.description"
                        style="height: 500px !important; min-height: 500px !important; margin-bottom: 100px !important"
                      />
                    </b-col>
                  </b-row>

                  <b-row v-if="is_tab_active === 5">
                    <b-col cols="12">
                      <WorkingHours
                        :id="profile.id"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 6">
                    <b-col cols="12">
                      <SocialMedia
                        :id="profile.id"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 7">
                    <b-col cols="12">
                      <Address />
                    </b-col>
                  </b-row>
                  <b-row v-if="is_tab_active === 8">
                    <b-col cols="12">
                      <Gallery
                        :id="profile.id"
                        :gallery="profile.gallery"
                        :update="is_update"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <div class="button_view d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                @click="is_update ? updateBusinessProfile() : createBusinessProfile()"
              >
                Save
              </b-button>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable,
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect, BFormCheckbox, BPagination, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { quillEditor } from 'vue-quill-editor'
import axios from 'axios'
import WorkingHours from './components/WorkingHours.vue'
import SocialMedia from './components/SocialMedia.vue'
import Address from './components/Address.vue'
import Gallery from './components/Gallery.vue'
import MultipleSelect from '../../@core/components/MultipleSelect.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import ServiceArea from './components/ServiceArea.vue'
import ServiceOffer from './components/ServiceOffer.vue'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BPagination,
    quillEditor,
    BAvatar,
    WorkingHours,
    ValidationProvider,
    ValidationObserver,
    SocialMedia,
    Address,
    Gallery,
    MultipleSelect,
    ServiceArea,
    ServiceOffer,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      has_license: false,
      selectedTags: [],
      required,
      perPage: 10,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      pageOptions: [3, 5, 10],
      currentPage: 1,
      email,
      longitude: -78.024902,
      latitude: 37.926868,
      map: null,
      map_address: '',
      fields: [{ key: 'cover', label: 'Cover Photo' }, { key: 'name', label: 'Name', sortable: true }, { key: 'state_id', label: 'State' }, { key: 'category_id', label: 'Category' }, { key: 'status', label: 'Status' }],
      selected: null,
      isModalShow: false,
      selected_user: null,
      selected_tags: [],
      profile: {
        member_id: 1,
        name: '',
        phone: '',
        email: '',
        city: '',
        address: '',
        state_id: null,
        county_id: null,
        zip_code: '',
        lng: null,
        lat: null,
        license: '',
        website: '',
        category_id: 1,
        sub_category_id: null,
        tags: [],
        contact_name: '',
        contact_title: '',
        contact_email: '',
        contact_phone: '',
        description: '',
      },
      tab_items: [
        { id: 1, title: 'Infomation' },
        { id: 2, title: 'Area Served' },
        { id: 3, title: 'Services Offered' },
        { id: 4, title: 'Description' },
        { id: 5, title: 'Working Hours' },
        { id: 6, title: 'Social Media' },
        { id: 7, title: 'Address' },
        { id: 8, title: 'Gallery' },
      ],
      is_tab_active: 1,
      map_enable: false,
    }
  },
  computed: {
    business_profiles() {
      return this.$store.state.businessProfile.admin_business_profiles
    },
    totalRows() {
      return this.business_profiles.length
    },
    profile_params() {
      return this.$store.state.businessProfile.admin_param
    },
    hours: {
      get() {
        return this.$store.state.workingHours.hours
      },
      set(value) {
        return value
      },
    },
    socia_media: {
      get() {
        return this.$store.state.socialMedia.socia_media
      },
      set(value) {
        return value
      },
    },
    maps: {
      get() {
        return this.$store.state.businessProfile.maps
      },
      set(value) {
        return value
      },
    },
    gallery_items() {
      return this.$store.state.businessProfile.gallery_items
    },
    cover_photo() {
      return this.$store.state.businessProfile.cover_photo
    },
    address: {
      get() {
        return this.$store.state.businessProfile.address
      },
      set(value) {
        return value
      },
    },
    users() {
      const data = this.$store.state.auth.users
      const member = data.filter(item => item.user_type === 'member')
      return member
    },
    user_options() {
      return this.users.map(item => {
        const data = { value: item.member.id, text: `${item.first_name} ${item.last_name}` }
        return data
      })
    },
    categories() {
      return this.$store.state.category.categories
    },
    sub_categories() {
      return this.$store.state.subCategory.sub_categories
    },
    categories_options() {
      return this.categories.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    sub_categories_options() {
      return this.sub_categories.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    tags() {
      return this.$store.state.tag.tags
    },
    tag_options() {
      return this.tags.map(item => {
        const data = { title: item.name, id: item.id }
        return data
      })
    },
    states() {
      return this.$store.state.state.states
    },
    service_area: {
      get() {
        return this.$store.state.serviceArea.service_area
      },
      set(value) {
        return value
      },
    },
    service_offer: {
      get() {
        return this.$store.state.serviceOffer.service_offer
      },
      set(value) {
        return value
      },
    },
    allCounties() {
      return this.$store.state.county.all_counties
    },
  },
  watch: {
    latitude(newValue) {
      if (this.map_enable === true) {
        this.map.setView([newValue, this.longitude], 13)
      }
    },
    longitude(newValue) {
      if (this.map_enable === true) {
        this.map.setView([this.latitude, newValue], 13)
      }
    },
    is_tab_active(newValue) {
      if (newValue === 2) {
        const dom = this
        setTimeout(() => {
          dom.initMap()
          dom.map_enable = true
        }, 300)
      } else {
        // eslint-disable-next-line keyword-spacing, no-lonely-if
        if(this.map_enable === true) {
          this.map_enable = false
          this.clearMap()
        }
      }
    },
    '$store.state.businessProfile.gallery_item': {
      handler(oldValue, newValue) {
        newValue.forEach(item => {
          this.profile.gallery.push(item)
        })
      },
      deep: true,
    },
    '$store.state.subCategory.all_sub_categories': {
      handler() {
        this.getSubCategory(1)
      },
      deep: true,
    },
  },
  mounted() {
    this.getBusinessProfile()
    this.getUsers()
    this.getDays()
  },
  methods: {
    initMap() {
      this.map = L.map(this.$refs.mapContainer).setView(
        [this.latitude, this.longitude],
        13,
      )
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map)

      L.marker([this.latitude, this.longitude], {
        icon: L.icon({
          // eslint-disable-next-line global-require
          iconUrl: require('leaflet/dist/images/marker-icon.png'),
          iconSize: [26, 37],
          iconAnchor: [22, 94],
          popupAnchor: [-3, -76],
        }),
      }).addTo(this.map)
    },
    clearMap() {
      this.map.remove() // remove the map instance before the component is destroyed
      this.map = null
    },
    getDays() {
      return this.$store.dispatch('businessProfile/getDays')
    },
    findCords() {
      const params = {
        text: this.map_address,
        apiKey: 'b8c01cc7450d4dc8851b6708190ed62c',
      }

      axios.get('https://api.geoapify.com/v1/geocode/search', { params })
        .then(response => {
          const property = response.data.features[0].properties
          // Extract the latitude and longitude from the first result
          const latitude = property.lat
          const longitude = property.lon
          this.map.setView([latitude, longitude], 13)
          L.marker([latitude, longitude], {
            icon: L.icon({
              // eslint-disable-next-line global-require
              iconUrl: require('leaflet/dist/images/marker-icon.png'),
              iconSize: [26, 37],
              iconAnchor: [22, 94],
              popupAnchor: [-3, -76],
            }),
          }).addTo(this.map)
          this.profile.lng = longitude
          this.profile.lat = latitude
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Address not found',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getBusinessProfile() {
      return this.$store.dispatch('businessProfile/getProfileVendor')
    },
    getImage(image) {
      if (image !== null || image !== '') {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return image
    },
    showFormContent(id) {
      this.is_tab_active = id
    },
    getUsers() {
      return this.$store.dispatch('auth/getUsers', { page: 1, per_page: 10, user_type: 'member' })
    },
    getSubCategory(id) {
      return this.$store.dispatch('subCategory/getSubCategories', id)
    },
    changeCategory() {
      return this.$store.dispatch('subCategory/getSubCategories', this.profile.category_id)
    },
    showModal() {
      this.$bvModal.show('app-modal')
      this.isModalShow = true
    },
    hide() {
      this.$store.dispatch('socialMedia/emptySocialData')
      this.$store.dispatch('workingHours/emptyData')
      this.$store.dispatch('serviceArea/emptyServiceAreaData')
      this.$store.dispatch('serviceOffer/emptyServiceOfferData')
      this.$store.dispatch('businessProfile/emptyAddress')
      this.$bvModal.hide('app-modal')
      this.isModalShow = false
      this.is_update = false
      this.emptyProfile()
      this.selectedTags = []
      this.map_address = ''
      this.is_tab_active = 1
    },
    getCounty(id) {
      return this.$store.dispatch('county/getCounties', id)
    },
    toggleBusinessProfile(status, id) {
      const data = { business_profile_id: id, status }
      return this.$store.dispatch('businessProfile/toggleBusinessProfile', data)
    },
    removeTags(item) {
      if (this.is_update === true) {
        const data = {
          business_profile_id: this.profile.id,
          tag_id: item.id,
        }
        this.$store.dispatch('businessProfile/deleteTags', data)
        this.getBusinessProfile()
      }
      this.selectedTags = this.selectedTags.filter(elm => elm.id !== item.id)
    },
    selectTags(item) {
      // eslint-disable-next-line camelcase
      const selected_data = this.selectedTags.filter(elm => elm.id === item.id)
      if (selected_data.length === 0) {
        this.selectedTags.push(item)
        if (this.is_update === true) {
          const data = {
            business_profile_id: this.profile.id,
            tags: [item.id],
          }
          this.$store.dispatch('businessProfile/addTags', data)
          this.getBusinessProfile()
        }
      }
    },
    showDetails(item) {
      const details = item
      this.profile = details
      this.address.address1 = details.address1
      this.map_address = details.address1
      this.longitude = details.lng
      this.latitude = details.lat
      this.address.city = details.city
      this.address.state_id = details.state_id
      this.address.zip_code = details.zip_code
      this.profile.lng = details.lng
      this.profile.lat = details.lat
      details.business_hours.forEach(elm => this.hours.unshift({
        id: elm.id,
        day_id: elm.day_id,
        business_hours_start: elm.business_hours_start,
        business_hours_end: elm.business_hours_end,
      }))
      details.social_handles.forEach(elm => this.socia_media.unshift({
        business_profile_id: elm.business_profile_id,
        id: elm.id,
        link: elm.link,
        type: elm.type,
      }))

      details.services.forEach(elm => this.service_offer.unshift({
        business_profile_id: elm.business_profile_id,
        id: elm.id,
        name: elm.name,
      }))
      details.service_areas.forEach(elm => {
        const countyOptions = this.allCounties.filter(filterData => filterData.state_id === elm.state_id)
        this.service_area.unshift({
          business_profile_id: elm.business_profile_id,
          id: elm.id,
          state_id: elm.state_id,
          county_id: elm.county_id,
          county_option: countyOptions.map(options => ({ value: options.id, text: options.name })),
        })
      })

      if (details.tags.length > 0) {
        this.selectedTags = details.tags.map(elm => ({ title: elm.name, id: elm.id }))
      }

      if (details.county_id !== undefined || details.county_id !== null) {
        this.address.county_id = details.county_id
        this.getCounty(details.state_id)
      }

      if (details.license !== '') {
        this.has_license = true
      }
      this.getSubCategory(details.category_id)
      this.is_update = true
      this.showModal()
    },
    changeHasLicense() {
      this.has_license = !this.has_license
    },
    createBusinessProfile() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          delete this.profile.address
          if (this.profile.sub_category_id === null) {
            delete this.profile.sub_category_id
          }
          const data = this.profile
          data.city = this.address.city
          data.address1 = this.address.address1
          data.state_id = this.address.state_id
          data.county_id = this.address.county_id
          data.zip_code = this.address.zip_code
          data.lng = Number(this.longitude)
          data.lat = Number(this.latitude)
          data.gallery_items = this.gallery_items
          data.business_hours = this.hours
          data.social_handles = this.socia_media
          data.cover_photo = this.cover_photo
          data.services = this.service_offer
          data.service_areas = this.service_area.map(item => ({ state_id: item.state_id, county_id: item.county_id }))
          if (this.selectedTags.length > 0) {
            data.tags = this.selectedTags.map(item => item.id)
          }
          this.$store.dispatch('businessProfile/createProfile', { item: data, param: this.profile_params })
          this.hide()
        }
      })
    },
    updateBusinessProfile() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          const data = this.profile
          data.city = this.address.city
          data.address1 = this.address.address1
          data.state_id = this.address.state_id
          data.county_id = this.address.county_id
          data.zip_code = this.address.zip_code
          data.lng = Number(this.profile.lng)
          data.lat = Number(this.profile.lat)
          data.cover_photo = this.cover_photo
          if (data.cover_photo.name === '') {
            delete data.cover_photo
          }
          delete data.gallery_items
          delete data.business_hours
          delete data.social_handles
          delete data.tags
          delete data.services
          delete data.service_areas
          delete data.cover
          this.$store.dispatch('businessProfile/updateProfile', { item: data, param: this.profile_params })
          this.hide()
        }
      })
    },
    deleteData() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('businessProfile/deleteProfile', { id: this.profile.id, param: this.profile_params })
          this.hide()
        }
      })
    },
    emptyProfile() {
      this.profile = {
        member_id: null,
        name: '',
        phone: '',
        email: '',
        city: '',
        address: '',
        state_id: null,
        county_id: null,
        zip_code: '',
        lng: null,
        lat: null,
        license: '',
        website: '',
        category_id: 1,
        sub_category_id: null,
        tags: [],
        contact_name: '',
        contact_title: '',
        contact_email: '',
        contact_phone: '',
        description: '',
      }
    },
    selectTag(data) {
      // console.log('add_tags', data)
    },
    onRemove(data) {
      // console.log('remove_tags', data)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
